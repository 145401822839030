<template>
<div>
	<q-markup-table flat>
		<thead>
			<tr>
				<th></th>
				<th v-for="a in actions">{{a.label}}</th>
			</tr>
		</thead>
		<tbody class="permissions">
		<tr v-for="role in roles">
			<td>
	           <q-input
	                v-if="role.edit"
	                v-model="role.label"
	                @blur="role.edit = false; $emit('update')"
	                @keyup.enter="role.edit=false; $emit('update')"
	                v-focus=""
	                hint="A descriptive name for this role"
	            />
	            <div v-else>
	                <label @click="role.edit = true;">
	                	<span class="text-h6">{{role.label}}</span>
	                </label>
	                <q-btn v-if="!role.system" style="float: right" dense round float-right flat color="grey" @click="remove(props)" icon="fad fa-trash"/>


	            </div>

			</td>
			<td align="center" v-for="a in actions">
				<q-checkbox
					:value="getCheckboxValue(role, a.code)"
					@input="v => { checkboxClick(v, role, a.code) }"
				/>
			</td>

		</tr>
		</tbody>
	</q-markup-table>


	<div class="q-mt-md flex flex-center" style="background-color: #eee">
		<q-btn flat label="Cancel" @click="cancel" color="secondary"/>
		<q-btn flat label="Save" @click="save" color="primary"/>
	</div>
</div>
</template>
<script>
import { API } from '../tic'
import { QSpinnerGears } from 'quasar'

export default {
	name: 'admin-roles',
	props: ['showAdd'],
	data() {
		return {
			filter: '',
			actions: [],
			roles: []
		}
	},
	watch: {
		showAdd () {
			this.roles.push({label: '', code: '', context: 'site', edit:true})

		}
	},

	computed: {
		title: function() {
			let a = this.showAdd ? 'Add' : 'Edit'
			return `${a} a role`
		}
	},

	mounted () {
		// toggle loading
		this.$q.loading.show()

		// get all groups
		this.load()

		// get permissions
		API.get('/system/group-permissions').then(r => {
			this.actions = r.data.data.actions

			this.$q.loading.hide()
		})
	},

  	directives: {
    	focus: {
      		inserted (el) {
        		el.focus()
      		}
    	}
  	},

	methods: {

		doCommand(e) {
			if (e.keyCode===27) {
				this.cancel()
			}

		},

		load() {
			// load the groups data
			API.get('/system/roles').then(r => {
				this.roles = r.data.data.roles
			})
		},

		cancel() {
			//this.$emit('hide')
		},

		remove(props) {
			this.$q.dialog({
        		title: '<i class="fas fa-trash"></i>&nbsp;Confirm',
        		message: `Really delete this item?<br><b>${props.row.name}</b>`,
        		cancel: true,
        		persistent: true,
        		html: true
      		}).onOk(() => {
	          	API.delete(`/system/groups/${props.key}`).then(r => {
	          		// reload data after delete
					this.load()
				})
			})
		},

		save () {
			// save the data, then clean local copy
			this.$q.loading.show({
				spinner: QSpinnerGears,
          		spinnerColor: 'green',
          		messageColor: 'green',
          		backgroundColor: 'lightgrey'
          	})

          	API.post('/system/group', {
          		code:this.code,
          		name:this.name,
          		perms:this.perms,
          		uuid:this.uuid ? this.uuid:null

          	}).then(r => {
          		this.$q.loading.hide()
          		this.$emit('hide')
          		this.showEdit=false
          		this.code=''
          		this.name=''
          		this.uuid=null
          		this.perms=clone(this.cleanPerms)
          		this.load()
          	})
		},

		// get the value for the checkbox, deal with missing entries
		getCheckboxValue(role, perm) {

			// create perms{} object is there is none
			if (role.perms === undefined) {
				role.perms = {}
			}

			if (role.perms[perm] === undefined) {
				role.perms[perm] = false
			}

			return role.perms[perm]
		},

		checkboxClick(value, role, perm) {
			role.perms[perm] = value
			// force an update, Vue is not smart enough to detect the mutation above.
			this.$forceUpdate();
		}
    }

}
</script>